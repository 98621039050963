import React, {FC, ReactNode} from 'react';
import './layout.scss'

interface CenterProps {
    children: ReactNode
}

const Center: FC<CenterProps> = ({children}) => {
    return (
        <div className="center">
            {children}
        </div>
    )
}

export default Center;