import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import OrderDto from "../../entity/order/OrderDto";

enableMapSet()

const name = 'orders'

export type LastEditableOrder = {
    ref: string
    comment?: string
}

interface OrdersState {
    isLoading: boolean,
    orders: OrderDto[]
    currentOrder?: OrderDto
    lastEditableOrder?: LastEditableOrder
}

const initialState: OrdersState = {
    isLoading: true,
    orders: [],
    lastEditableOrder: undefined
}

export const orderSlice = createSlice({
    name,
    initialState,
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload
        },
        setLastEditOrder: (state, action: PayloadAction<LastEditableOrder>) => {
            state.lastEditableOrder = action.payload
        },
        clearLastEditOrder: (state, action) => {
            state.lastEditableOrder = undefined
        }
    }
})

export const {setOrders, setLastEditOrder, clearLastEditOrder} = orderSlice.actions
export default orderSlice.reducer
