import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {AbstractSate} from "./AbstractSate";
import {TAdvancedMenuItem} from "../../components/cards/nomenclature/NomenclatureCards";

enableMapSet()

const name = 'detailsProduct'

interface DetailsProductState extends AbstractSate {
    isShow: boolean
    product?: TAdvancedMenuItem
    isComplexSet?: boolean
    isEditComplex?: boolean
}

const initialState: DetailsProductState = {
    isLoading: true,
    isShow: false,
    error: undefined,
    product: undefined
}

interface IProductDetails {
    product: TAdvancedMenuItem
    isComplexSet: boolean
    isEditComplex: boolean
}

export const detailsProductSlice = createSlice({
    name,
    initialState,
    reducers: {
        toggleDialog: (state, action) => {
            state.isShow = !current(state.isShow)
        },
        hide: (state, action) => {
            state.isShow = false
            state.product = undefined
            state.isComplexSet = false
            state.isEditComplex = false
        },
        open: (state, action) => {
            state.isShow = true
        },
        openForProduct: (state, {payload}: PayloadAction<IProductDetails>) => {
            let product = payload.product

            if (product?.nomenclature) {
                let nomenclature = product.nomenclature
                nomenclature.carbs = Math.ceil(nomenclature.carbs) || 0
                nomenclature.fats = Math.ceil(nomenclature.fats) || 0
                nomenclature.proteins = Math.ceil(nomenclature.proteins) || 0
                nomenclature.calories = Math.ceil(nomenclature.calories) || 0
                product = {...product,  nomenclature: {...product.nomenclature, ...nomenclature}}
            }

            state.product = product
            state.isComplexSet = payload.isComplexSet
            state.isEditComplex = payload.isEditComplex
            state.isShow = true
        }
    }
})

export const {toggleDialog, open, hide, openForProduct} = detailsProductSlice.actions
export default detailsProductSlice.reducer

