import {createAsyncThunk} from "@reduxjs/toolkit";
import AuthService from "../../entity/auth/AuthService";
import {IAvailableAuthTypes} from "../../entity/auth/AuthDto";

export const availableAuthTypes = createAsyncThunk('auth/getAvailableAuthTypes',
    async (debug: string, {}) => {
    const resp = await AuthService.getAvailableTypes(debug)
    return new Promise<IAvailableAuthTypes>(resolve => {
        setTimeout(() => resolve(resp.body), 500)
    })
})
