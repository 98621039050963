import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"

enableMapSet()

const name = 'notifications'

interface NotificationsState {
    countUnreadNotifications: number
}

const initialState: NotificationsState = {
    countUnreadNotifications: 0
}

export const notificationSlice = createSlice({
    name,
    initialState,
    reducers: {
        setCountUnread: (state, {payload}: PayloadAction<number>) => {
            state.countUnreadNotifications = payload
        }
    }
})

export const {setCountUnread} = notificationSlice.actions
export default notificationSlice.reducer
