import React, {FC} from 'react';
import {InfoCircleFill} from "react-bootstrap-icons";
import svg from '../../assets/error.svg'
import './style.scss'

interface ErrorBlockProps {
    error?: string
}

const ErrorBlock: FC<ErrorBlockProps & Partial<Exclude<HTMLDivElement, "className">>> = ({error, className}) => {
    if (!error)
        return null

    return (
        <>
            <div className={`error ${className || ''}`}>
                {/*<InfoCircleFill size={16} color="var(--color-text)"/>*/}
                <img src={svg} alt="missing"/>
                <label>
                    {
                        error
                    }
                </label>
            </div>
        </>
    );
}

export default ErrorBlock;
