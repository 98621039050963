import {enableMapSet} from "immer";
import {AbstractSate} from "./AbstractSate";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

enableMapSet()

const name = 'menu'

export enum TYPE_VIEW_MENU {
    GRID = 'GRID',
    LIST = 'LIST'
}

interface MenuState extends AbstractSate {
    typeMenu: TYPE_VIEW_MENU
}

const initialState: MenuState = {
    typeMenu: TYPE_VIEW_MENU.GRID,
    isLoading: false,
    error: ''
}

export const menuSlice = createSlice({
    name,
    initialState,
    reducers: {
        setTypeMenu: (state, {payload}: PayloadAction<TYPE_VIEW_MENU>) => {
            state.typeMenu = payload
        }
    }
})

export const {setTypeMenu} = menuSlice.actions
export default menuSlice.reducer
