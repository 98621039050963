import {useCallback, useState} from "react";
import {AxiosError} from "axios";

type TFetching<T> = (args: T) => Promise<void>
type TCallback<T> = (args: T) => any

export function useFetching<T>(callback: TCallback<T>): [fetching: TFetching<T>, isLoading: boolean, error: string] {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching: TFetching<T> = useCallback(async (args: T) => {
        try {
            setError('');
            setIsLoading(true);
            await callback(args);
        } catch (e) {
            console.log(e)

            if (e instanceof Error)
                setError("Что-то пошло не так. Обновите страницу");
            else if (e instanceof AxiosError) {
                setError(e.response?.data?.message)
            } else setError("Ошибка выполнения запроса")
        } finally {
            setIsLoading(false);
        }
    }, [callback]);

    return [fetching, isLoading, error]
}
