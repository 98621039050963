import React, {FC} from "react";
import "./style.scss"
import Logo from "./Logo";

interface IProps {
    rightElements?: React.ReactNode
    leftElements?: React.ReactNode
}

const Toolbar: FC<IProps> = ({rightElements, leftElements}) => {
    return (
        <header className="navbar toolbar">
            <div className="wrapElements container">
                <div className="logoWrap">
                    <Logo/>
                </div>
                <div className="leftMenu">
                    {leftElements}
                </div>
                {rightElements}
            </div>
        </header>
    )
}

export default Toolbar
