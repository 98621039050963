import React, {FC} from 'react';
import './loading.scss'
import Spinner from "react-bootstrap/Spinner";

interface LoaderProps {
    size?: "sm" | undefined
}

const Loader: FC<LoaderProps & Partial<Exclude<HTMLDivElement, "className">>> = ({size = undefined, className}) => {
    return (
        <div className="containerLoader">
            <Spinner variant="primary" className={`loader ${className}`} role="status" animation="border" size={size}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default Loader;
