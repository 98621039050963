import {FC, useCallback, useEffect, useMemo, useState} from "react";
import Toast from "react-bootstrap/Toast";
import {BellFill, CheckCircleFill, ExclamationCircleFill, InfoCircleFill, XCircleFill} from "react-bootstrap-icons";
import {useAppDispatch} from "../../redux/hooks";
import {remove} from "../../redux/reducers/alertSlice";
import {IAlert, TYPE_ALERT} from "../../entity/notification/alert";
import {notificationApi} from "../../api/notificationApi";
import {NavigateFunction} from "react-router-dom";
import DataFormat from "../../utils/DataFormat";

type ToastBaseProps = IAlert & {
    duration?: number
    id?: number | string
    onClick?: () => void
    navigate: NavigateFunction
}

const ToastBase: FC<ToastBaseProps> =
    ({
         duration = 4000, id, title, description, navigate,
         type = TYPE_ALERT.info, autoClear = true, onClick, notification, ...props
     }) => {
        const dispatch = useAppDispatch()
        const [fetchReadById] = notificationApi.useReadByIdNotificationsMutation()
        const [show, setShow] = useState(true);
        const [color, setColor] = useState('var(--primary)')

        useEffect(() => {
            if (autoClear) {
                setTimeout(() => {
                    setShow(false)
                    dispatch(remove(id))
                }, duration)
            }
        }, []) // eslint-disable-line react-hooks/exhaustive-deps

        const handleClose = useCallback(() => {
            setShow(false)
            dispatch(remove(id))
        }, [id])

        const icon = useMemo(() => {
            switch (type) {
                case TYPE_ALERT.info:
                    setColor("var(--primary)")
                    return (<InfoCircleFill className="rounded me-2" color={"var(--primary)"}/>)
                case TYPE_ALERT.success:
                    setColor("var(--success)")
                    return (<CheckCircleFill className="rounded me-2" color={"var(--success)"}/>)
                case TYPE_ALERT.warning:
                    setColor("var(--warning)")
                    return (<ExclamationCircleFill className="rounded me-2" color={"var(--warning)"}/>)
                case TYPE_ALERT.alert:
                    setColor("var(--primary)")
                    return (<BellFill className="rounded me-2" color={"var(--primary)"}/>)
                case TYPE_ALERT.error:
                    setColor("var(--danger)")
                    return (<XCircleFill className="rounded me-2" color={"var(--danger)"}/>)
                default:
                    setColor("var(--primary)")
                    return (<BellFill className="rounded me-2" color={"var(--primary)"}/>)
            }
        }, [type]);

        const onClickNotification = useCallback(() => {
            if (notification) {
                let path = DataFormat.pathForNotification(notification)

                if (path && !!notification?.ref) {
                    fetchReadById({id: notification?.ref, personnelNumber: notification.personnelNumber}).then(() => {
                        handleClose()
                        navigate(path)
                    })
                }
            }
        }, [])

        const handleCloseToast = (e: any) => {
            e?.stopPropagation()
            handleClose()
        }

        return (
            <Toast className="toast" show={show} onClose={handleCloseToast}
                   onClick={notification?.data ? onClickNotification : onClick}>
                <div style={{backgroundColor: `${color}`, borderRadius: "6px 6px 0 0"}}>
                    <Toast.Header className="header">
                        {icon}
                        <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                </div>
                <Toast.Body className="body">{description}</Toast.Body>
            </Toast>
        )
    }

export default ToastBase
