import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
    persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist';
import cartReducer from "../redux/reducers/cartSlice"
import profileReducer from "../redux/reducers/profileSlice"
import authReducer from "../redux/reducers/authSlice"
import detailsProductReducer from "../redux/reducers/detailsProductSlice"
import orderReducer from "../redux/reducers/orderSlice"
import divisionReducer from "../redux/reducers/divisionSlice"
import catalogReducer from "../redux/reducers/catalogSlice"
import alertReducer from "./reducers/alertSlice"
import menuReducer from "../redux/reducers/menuSlice"
import notificationReducer from "../redux/reducers/notificationsSlice"
import {orderApi} from "../api/orderApi";
import storage from "redux-persist/lib/storage";
import {getPersistConfig} from 'redux-deep-persist';
import {rtkQueryErrorLogger} from "../api";
import {notificationApi} from "../api/notificationApi";

const rootReducer = combineReducers({
    authReducer,
    cartReducer,
    menuReducer,
    orderReducer,
    profileReducer,
    catalogReducer,
    divisionReducer,
    alertReducer,
    detailsProductReducer,
    notificationReducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer
})

const persistConfig = getPersistConfig({
    key: 'root',
    storage: storage,
    whitelist: [
        'menuReducer.typeMenu',
        'divisionReducer.currentDate',
        'divisionReducer.currentMealRef',
        'divisionReducer.currentDivisionRef'
    ],
    rootReducer: rootReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredPaths: ['alertReducer.alerts', 'divisionReducer.availableDates', 'profileReducer.clientData', 'detailsProductReducer.product.date',
                'divisionReducer.currentDate', 'cartReducer.cart.date', 'orderReducer.orders', 'cartReducer.cart', 'cartReducer.carts'],
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'divisions/setAvailableDates', 'detailsProduct/openForProduct',
                'carts/setCart', 'orders/setOrders', 'divisions/setCurrentDate', 'profile/setClientData', 'carts/setCarts', 'alerts/push'],
        }
    }).concat(rtkQueryErrorLogger, orderApi.middleware, notificationApi.middleware)
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
