import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {Alert, IAlert} from "../../entity/notification/alert";

enableMapSet()

const name = 'alerts'

interface AlertsState {
    alerts: Alert[]
}

const initialState: AlertsState = {
    alerts: []
}

export const alertSlice = createSlice({
    name,
    initialState,
    reducers: {
        push: (state, {payload}: PayloadAction<IAlert | Alert>) => {
            // @ts-ignore
            if (!state.alerts.find(e => e.id === payload['id'])) {
                if (payload instanceof Alert)
                    state.alerts.push(Alert.Extend(payload.id, payload.title, payload.description, payload.type, payload.autoClear, payload.notification))
                else
                    state.alerts.push(new Alert(payload.title, payload.description, payload.type, payload.autoClear))
            }
        },
        remove: (state, {payload}: PayloadAction<number | string | undefined>) => {
            if (payload !== undefined)
                state.alerts = state.alerts.filter(n => n.id !== payload)
        },
        clearAll: (state) => {
            state.alerts = []
        }
    }
})

export const {push, remove, clearAll} = alertSlice.actions
export default alertSlice.reducer

