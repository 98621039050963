import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import ToastProvider from "./components/toast/ToastProvider";
import {PersistGate} from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <BrowserRouter>
                <App/>
                <ToastProvider/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

Object.defineProperty(String.prototype, 'toUpperFirstChart', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});
