import React, {createContext, FC, useCallback, useContext, useState} from "react";
import './style.scss'
import ToastContainer from "react-bootstrap/ToastContainer";
import ToastBase from "./ToastBase";
import {useAppSelector} from "../../redux/hooks";
import {useNavigate} from "react-router-dom";

const ToastProvider: FC = () => {
    const navigate = useNavigate()
    const {alerts} = useAppSelector(state => state.alertReducer)

    return (
        <ToastContainer className="stickyContainerBottom">
            {
                alerts.map(alert => {
                    return (<ToastBase key={alert.id} {...alert} navigate={navigate}/>)
                })
            }
        </ToastContainer>
    )
}

export default ToastProvider
