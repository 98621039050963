import React from "react";

const UseScrollLock = () => {
    const lockScroll = React.useCallback(() => {
        document.body.dataset.scrollLock = 'true';
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar-compensation)';
    }, [])

    const unlockScroll = React.useCallback(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = ''
        delete document.body.dataset.scrollLock;
    }, []);

    const isLockScroll = React.useCallback(() => {
        return !!document.body.dataset.scrollLock
    }, []);

    React.useLayoutEffect(() => {
        const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
        document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`);
    }, [])

    return {
        lockScroll,
        unlockScroll,
        isLockScroll
    };
}

export default UseScrollLock;
