import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {AbstractSate} from "./AbstractSate";
import AvailableDate from "../../entity/availableDate/AvailableDate";
import MealInline from "../../entity/meal/MealInline";
import {ClientDivision} from "../../entity/clientProfile/ClientProfile";

enableMapSet()

const name = 'divisions'

interface DivisionState extends AbstractSate {
    isLoading: boolean,
    divisions: ClientDivision[]
    currentDivision?: ClientDivision
    availableDates: AvailableDate[]
    currentAvailableDate?: AvailableDate
    currentMeal?: MealInline
    currentDate?: string
    currentMealRef?: string
    currentDivisionRef: string
}

const initialState: DivisionState = {
    isLoading: true,
    error: null,
    divisions: [],
    availableDates: [],
    currentDivisionRef: ""
}

export const divisionSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setDivisions: (state, action: PayloadAction<ClientDivision[]>) => {
            state.divisions = action.payload

            if (!state.currentDivisionRef) {
                const first = action.payload.at(0)
                state.currentDivision = first
                state.currentDivisionRef = first?.divisionRef ? first?.divisionRef : ""
            } else {
                let found = action.payload.find(d => d.divisionRef === state.currentDivisionRef)

                if (found) {
                    state.currentDivision = found
                    state.currentDivisionRef = found.divisionRef
                } else {
                    const first = action.payload[0]
                    state.currentDivision = first
                    state.currentDivisionRef = first ? first.divisionRef : ""
                    // state.currentMealRef = undefined
                    // state.currentMeal = undefined
                    // state.currentDate = undefined
                    // state.availableDates = []
                }
            }
        },
        setCurrentDivision: (state, action: PayloadAction<ClientDivision>) => {
            state.currentDivisionRef = action.payload?.divisionRef
            state.currentDivision = action.payload
        },
        setAvailableDates: (state, action: PayloadAction<AvailableDate[]>) => {
            state.availableDates = action.payload

            const first = action.payload.at(0)
            const foundDate = action.payload.find(ad => ad.menuDate === state.currentDate)
            state.currentDate = foundDate ? foundDate.menuDate : first?.menuDate

            const foundMealByCurrentDate = foundDate && foundDate.meals.find(m => m.mealRef === state.currentMealRef)
            let foundMeal = foundMealByCurrentDate ? foundMealByCurrentDate : foundDate ? foundDate.meals[0] : first?.meals[0]
            state.currentMeal = foundMeal
            state.currentMealRef = foundMeal?.mealRef

            state.isLoading = false
        },
        setCurrentDate: (state, action: PayloadAction<string>) => {
            state.currentDate = action.payload
        },
        setCurrentMealRef: (state, action: PayloadAction<string>) => {
            state.currentMealRef = action.payload
            if (state.availableDates && state.currentDate)
                state.currentMeal = state.availableDates.find(ab => ab.menuDate === state.currentDate)?.meals.find(m => m.mealRef === action.payload)
        },
        clearData: (state) => {
            state.currentDate = undefined
            state.availableDates = []
            state.currentMeal = undefined
            state.currentMealRef = undefined
            state.currentAvailableDate = undefined
            state.error = undefined
            state.isLoading = true
        }
    }
})

export const {
    clearData,
    setDivisions,
    setCurrentDivision,
    setAvailableDates,
    setCurrentDate,
    setCurrentMealRef
} = divisionSlice.actions
export default divisionSlice.reducer

