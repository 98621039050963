import React, {FC, useState} from 'react';
import "./dialog.scss"
import {Button, Modal} from "react-bootstrap";
import {Box, X, XCircleFill} from "react-bootstrap-icons";
import {useConfirmDialog} from "./ConfirmDialogContext";
import Loader from "../UI/loader/Loader";
import ErrorBlock from "../info/ErrorBlock";

interface ConfirmDialogProps {
}

const ConfirmDialog: FC<ConfirmDialogProps> = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("");
    const {
        reject,
        accept,
        rejectLabel,
        acceptLabel,
        isOpen,
        isNotificationType,
        message,
        title,
        onHide
    } = useConfirmDialog()

    if (!isOpen) return null;

    const rejectHandle = async () => {
        if (reject)
            await reject()

        await onHide()
        setError("")
    }

    const acceptHandle = async () => {
        if (accept) {
            setIsLoading(true)
            setError("")
            await accept()
                .then(async (r) => await onHide())
                .catch(r => {
                    setError(r)
                })
                .finally(() => setIsLoading(false))
        } else {
            await onHide()
            setError("")
        }
    }

    return (
        <Modal className="modalDialog confirmDialog" show={isOpen}
               aria-labelledby="contained-modal-title-vcenter" centered>
            <div><X className="btnClose" onClick={rejectHandle}/></div>
            <Modal.Body className="body">
                {title}
                {
                    isLoading ? <Loader className="mt-5"/> : <>
                        <p>
                            {message}
                        </p>
                    </>
                }
                {
                    error ? <>
                        <ErrorBlock className="mt-2" error={error}/>
                    </> : <></>
                }
            </Modal.Body>
            {
                !isNotificationType && <Modal.Footer className="footer">
                    <Button disabled={isLoading} className="btn" onClick={acceptHandle}>{acceptLabel}</Button>
                    <Button disabled={isLoading} className="btn btn-secondary" onClick={rejectHandle}>{rejectLabel}</Button>
                </Modal.Footer>
            }
        </Modal>
    )

}

export default ConfirmDialog;
