import {INotificationDto} from "./index";

export enum TYPE_ALERT {
    success = 'success',
    warning = 'warning',
    error = 'error',
    alert = 'alert',
    info = 'info'
}

export interface IAlert {
    title: string
    description: string
    type: TYPE_ALERT
    autoClear?: boolean
    notification?: INotificationDto
}

export class Alert implements IAlert {
    id: string;
    title: string;
    type: TYPE_ALERT;
    description: string;
    autoClear?: boolean
    notification?: INotificationDto

    constructor(title: string, description: string, type: TYPE_ALERT, autoClear?: boolean, notification?: INotificationDto) {
        this.id = Date.now().toString()
        this.title = title
        this.description = description
        this.type = type
        this.autoClear = autoClear
        this.notification = notification
    }

    set setId(_id: string ) {
        this.id = _id
    }

    static Extend(id: string | undefined, title: string, description: string, type: TYPE_ALERT, autoClear?: boolean, notification?: INotificationDto) {
        let alert = new Alert(title, description, type, autoClear, notification);
        alert.setId = id ? id : Date.now().toString()
        return alert
    }
}
