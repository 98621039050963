import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PagePaginationApiResponse} from "../common/dto/PageApiResponse";
import OrderDto, {CreateOrderDto, OrderNomenclatureBenefit, OrderStatusDto} from "../entity/order/OrderDto";
import OrderPatchDto from "../entity/order/OrderPatchDto";
import DataFormat from "../utils/DataFormat";
import EntityApiResponse from "../common/dto/EntityApiResponse";
import {baseQueryWithReAuth} from "./index";

export type BenefitsTypeRef = {
    benefitsTypeRef: string
}

export type PaymentTypeRef = {
    paymentTypeRef: string
}

export interface ISelectedPaymentData {
    usedBenefits: BenefitsTypeRef[]
    usedPayments: PaymentTypeRef[]
}

interface IQuantitativeBenefit {
    benefitsTypeRef: string
    nomenclatureBenefits: OrderNomenclatureBenefit[]
}

export interface ISelectedQuantitativeBenefitsData {
    quantitativeBenefits: IQuantitativeBenefit[]
}

export const orderApi = createApi({
    reducerPath: 'orders',
    tagTypes: ['Order', 'Orders'],
    refetchOnReconnect: true,
    baseQuery: baseQueryWithReAuth,
    endpoints: (build) => ({
        getAllOrders: build.query<PagePaginationApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, where?: string[] }>({
            query: ({divisionId, personnelNumber, where}) => {
                let params = {
                    divisionId: divisionId,
                    sort: 'createdAt desc',
                    personnelNumber: personnelNumber
                }

                if (where) {
                    const whereObj = DataFormat.strArrayToObject(where)
                    params = {...params, ...whereObj}
                }

                return ({
                    url: 'orders/',
                    method: 'GET',
                    params
                })
            },
            providesTags: ['Order', "Orders"]
        }),
        getNotOrderedOrders: build.query<PagePaginationApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, where?: string[] }>({
            query: ({divisionId, personnelNumber, where}) => {
                let params = {
                    divisionId: divisionId,
                    sort: 'createdAt desc',
                    personnelNumber: personnelNumber
                }

                if (where) {
                    const whereObj = DataFormat.strArrayToObject(where)
                    params = {...params, ...whereObj}
                }

                return ({
                    url: 'orders/',
                    method: 'GET',
                    params
                })
            },
        }),
        getOrders: build.mutation<PagePaginationApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, where?: string[] }>({
            query: ({divisionId, personnelNumber, where}) => {
                let params = {
                    divisionId: divisionId,
                    sort: 'createdAt desc',
                    personnelNumber: personnelNumber
                }

                if (where) {
                    const whereObj = DataFormat.strArrayToObject(where)
                    params = {...params, ...whereObj}
                }

                return ({
                    url: 'orders/',
                    method: 'GET',
                    params
                })
            }
        }),
        pathOrder: build.mutation<EntityApiResponse<OrderDto>, {
            divisionId: string, orderRef?: string,
            personnelNumber: string, pathOrder: OrderPatchDto
        }>({
            query: ({divisionId, orderRef, personnelNumber, pathOrder}) => ({
                url: `orders/${orderRef}`,
                method: 'PATCH',
                body: pathOrder,
                params: {
                    divisionId,
                    personnelNumber
                }
            }),
            invalidatesTags: ['Order']
        }),
        getOrder: build.query<EntityApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, orderId?: string | undefined }>({
            query: ({divisionId, personnelNumber, orderId}) => {
                return {
                    url: `orders/${orderId}`,
                    params: {
                        divisionId,
                        personnelNumber
                    }
                }
            },
            providesTags: ['Order']
        }),
        selectPaymentOrder: build.mutation<EntityApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, orderId?: string | undefined, data: ISelectedPaymentData }>({
            query: ({divisionId, personnelNumber, orderId, data}) => {
                return {
                    url: `orders/${orderId}/usedPayments`,
                    method: 'PUT',
                    body: data,
                    params: {
                        divisionId,
                        personnelNumber
                    }
                }
            },
            invalidatesTags: ['Order']
        }),
        setQuantitativeBenefits: build.mutation<EntityApiResponse<OrderDto>,
            { divisionId: string, personnelNumber: string, orderId?: string | undefined, data: ISelectedQuantitativeBenefitsData }>({
            query: ({divisionId, personnelNumber, orderId, data}) => {
                return {
                    url: `orders/${orderId}/quantitativeBenefits`,
                    method: 'PUT',
                    body: data,
                    params: {
                        divisionId,
                        personnelNumber
                    }
                }
            },
            invalidatesTags: ['Order']
        }),
        getOrderStatuses: build.query<EntityApiResponse<OrderStatusDto[]>, null>({
            query: () => {
                return ({cache: 'force-cache',
                    headers: { 'Cache-Control': 'public, max-age=86400' },
                    url: 'orders/statuses',
                    method: 'GET',
                })
            },
        }),
        removeOrder: build.mutation<EntityApiResponse<OrderDto>, { divisionId: string, personnelNumber: string, orderId?: string | undefined }>({
            query: ({orderId, personnelNumber, divisionId}) => {
                return ({
                    url: `orders/${orderId}`,
                    method: 'DELETE',
                    params: {
                        divisionId,
                        personnelNumber
                    }
                })
            },
            invalidatesTags: ['Order']
        }),
        createOrder: build.mutation<EntityApiResponse<OrderDto>, { divisionId: string, personnelNumber: string, order: CreateOrderDto }>({
            query: ({divisionId, personnelNumber, order}) => {
                return ({
                    url: `orders/`,
                    method: "POST",
                    body: order,
                    params: {
                        divisionId,
                        personnelNumber
                    }
                })
            },
            invalidatesTags: ["Orders", "Order"]
        })
    })
})

export const {useLazyGetAllOrdersQuery, useLazyGetOrderQuery} = orderApi
