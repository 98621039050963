import {createSlice, current, PayloadAction} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"
import {AbstractSate} from "./AbstractSate";
import CartDto from "../../entity/cart/CartDto";
import {CartItemIncomingComplexConstructor, ItemIncomingComplexConstructor} from "../../entity/cart/CartItemIncoming";
import DateUtils from "../../utils/DateUtils";

enableMapSet()

const name = 'carts'

interface CartState extends AbstractSate {
    isLoading: boolean
    carts: CartDto[]
    cart?: CartDto
    complexConstructor?: CartItemIncomingComplexConstructor
    countItemsInToCart: number
    isShowBanner: boolean
}

const initialState: CartState = {
    isLoading: true,
    error: null,
    countItemsInToCart: 0,
    carts: [],
    cart: undefined,
    isShowBanner: false
}

export const cartSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setCarts: (state, action: PayloadAction<CartDto[]>) => {
            const {payload} = action
            state.carts = payload.filter(i => i.items.length > 0)
            state.isLoading = false
        },
        clearCart: (state) => {
            state.cart = undefined
            state.countItemsInToCart = 0
        },
        setCart: (state, action: PayloadAction<CartDto | undefined>) => {
            if (action.payload) {
                const {ref, items, allowedUntil} = action.payload;

                if (items?.length > 0) {
                    state.carts = state.carts.map((cart) => (cart.ref === ref ? action.payload as CartDto : cart));
                    state.countItemsInToCart = items.length;
                    state.isShowBanner = DateUtils.isClosedOfTime(allowedUntil);
                } else {
                    state.carts = state.carts.filter((cart) => cart.ref !== ref);
                    state.countItemsInToCart = 0;
                    state.isShowBanner = false;
                }

                state.cart = action.payload;
            } else {
                state.cart = undefined;
                state.countItemsInToCart = 0;
                state.isShowBanner = false;
            }
        },
        setCountItemsInCart: (state, action: PayloadAction<number>) => {
            state.countItemsInToCart = action.payload
        },
        incCountItemsInCart: state => {
            state.countItemsInToCart += 1
        },
        setShowBannerCart: (state, action) => {

        },
        initialComplexConstructor: (state, action) => {
            state.complexConstructor = action.payload
        },
        addToComplexSet: (state, action: PayloadAction<ItemIncomingComplexConstructor>) => {
            const complexConstructor = state.complexConstructor
            let items = complexConstructor?.cartComplexSetItems

            if (!items)
                items = []

            const foundItems = items.filter(i => i.refCategory === action.payload.refCategory)
            if (action.payload.isChecked) {
                if (!foundItems.length) {
                    items.push(action.payload)
                } else {
                    const first = foundItems[0]
                    const {countRequireInCategory} = first

                    if (countRequireInCategory === foundItems.length) {
                        items = [...items.filter(i => i.nomenclatureRef !== first.nomenclatureRef), action.payload]
                    } else
                        items.push(action.payload)
                }
            } else {
                items = [...items.filter(i => i.nomenclatureRef !== action.payload.nomenclatureRef)]
            }

            if (state.complexConstructor)
                state.complexConstructor.cartComplexSetItems = items;
        },
        setShowBanner: (state, action) => {
            state.isShowBanner = action.payload;
        }
    }
})

export const {
    setCarts,
    setCart,
    clearCart,
    incCountItemsInCart,
    setCountItemsInCart,
    addToComplexSet,
    setShowBanner,
    initialComplexConstructor
} = cartSlice.actions
export default cartSlice.reducer

