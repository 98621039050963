import React, {createContext, FC, useContext, useState} from "react";

type HandleButton = () => Promise<void>

export interface IConfirmDialog {
    accept?: HandleButton;
    reject?: HandleButton;
    isOpen: boolean
    isNotificationType?: boolean
    acceptLabel?: string;
    rejectLabel?: string;
    message?: string
    title: string
}

export interface IConfirmDialogContext extends IConfirmDialog {
    confirmDialog: (data: IConfirmDialog) => void
    onHide: HandleButton
    onShow: HandleButton
}

const defaultContext: IConfirmDialogContext = {
    accept: async () => {
    },
    reject: async () => {
    },
    onShow: async () => {},
    onHide: async () => {},
    isOpen: false,
    isNotificationType: false,
    acceptLabel: "",
    rejectLabel: "",
    title: "",
    confirmDialog: (data) => {
    }
}

export const ConfirmDialogContext = createContext<IConfirmDialogContext>(defaultContext);

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

interface ConfirmDialogProviderProps {
    children: React.ReactNode
}

const ConfirmDialogProvider: FC<ConfirmDialogProviderProps> = ({children, ...props}) => {
    const [dataDialog, setDataDialog] = useState<IConfirmDialog>({
        acceptLabel: "Подтвердить",
        rejectLabel: "Отменить",
        title: "",
        reject: async () => {
           await onHide()
        },
        accept: async () => {
            await onHide()
        },
        isOpen: false,
        isNotificationType: false
    })

    function confirmDialog(data: IConfirmDialog) {
        setDataDialog({...dataDialog, isNotificationType: false, ...data})
    }

    const onHide = async () => {
        setDataDialog({...dataDialog, message: undefined, isOpen: false})
    }

    const onShow = async () => {
        setDataDialog({...dataDialog, isOpen: true})
    }

    return (
        <ConfirmDialogContext.Provider value={{...dataDialog, confirmDialog, onHide, onShow}} {...props}>
            {children}
        </ConfirmDialogContext.Provider>
    );
}

export default ConfirmDialogProvider;
