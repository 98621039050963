import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query/react";
import AuthService from "../entity/auth/AuthService";
import {authHeaderValue, clearTokensLocalStorage, getTokensLocalStorage, setTokensLocalStorage} from "./axiosInstances";
import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import LocalStorageService from "../entity/storage/LocalStorageService";

const {REACT_APP_HOST_BACKEND_DEV, REACT_APP_HOST_BACKEND_PROD, REACT_APP_STAGE} = process.env
let HOST_BACKEND = REACT_APP_STAGE === "DEV" ? REACT_APP_HOST_BACKEND_DEV : REACT_APP_HOST_BACKEND_PROD

export const baseQuery = fetchBaseQuery({
    baseUrl: `${HOST_BACKEND}`,
    prepareHeaders: (headers, api) => {
        const authHeaders = authHeaderValue()
        headers.set('Authorization', authHeaders)
        headers.set('app', 'web')
        return headers
    }
})

export const baseFetch = fetchBaseQuery({
    baseUrl: `${HOST_BACKEND}`,
    prepareHeaders: (headers, api) => {
        let tokens = getTokensLocalStorage()
        headers.set('Authorization', `Bearer ${tokens?.refreshToken}`)
        return headers
    }
})

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions)

        if (result.error && (result.error.status === 401 || result.error.status === 403) && !window.location.href.includes('auth')) {
            let tokens = getTokensLocalStorage()
            if (tokens?.refreshToken) {
                const refreshToken = tokens.refreshToken
                const resp = await AuthService.getTokens(refreshToken)

                if (resp && resp.body) {
                    await setTokensLocalStorage(resp.body)
                    result = await baseQuery(args, api, extraOptions)
                    return result
                } else {
                    await clearTokensLocalStorage()
                    LocalStorageService.setValue('lastPath', window.location.pathname)
                    window.location.replace(`/auth`)
                    return Promise.reject("Авторизуйтесь");
                }
            } else {
                LocalStorageService.setValue('lastPath', window.location.pathname)
                window.location.replace(`/auth`)
            }
        }

        return result
    }

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            action.error = action.payload.data.message
        }

        return next(action)
    }
