import React, {FC} from 'react';
import './loading.scss'
import Spinner from "react-bootstrap/Spinner";

interface FullPageLoadingProps {

}

const FullPageLoading: FC<FullPageLoadingProps> = ({}) => {

    return (
        <div className={"wrapLoadingFullPage"}>
            <div className="spinner">
                <Spinner role="status" variant="primary" animation="border">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </div>
    );
}

export default FullPageLoading;
