import {createSlice} from '@reduxjs/toolkit'
import {enableMapSet} from "immer"

enableMapSet()

const name = 'catalog'

const initialState = {
    isLoading: true,
    catalog: []
}

export const catalogSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setCatalog: (state, action) => {
            state.catalog = action.payload
            state.isLoading = false
        }
    }
})

export const {setCatalog} = catalogSlice.actions
export default catalogSlice.reducer

