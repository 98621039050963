export interface IFilter{
    divisionId: string;
    date: string | null;
    mealId: string;
    apiDate(): string;
    isOk(): boolean;
}

export class TFilter implements IFilter{
    isOk(): boolean {
        return Boolean(this.divisionId && this.date && this.mealId);
    }

    apiDate(): string {
        return this.date ? this.date : '';
    }

    date: string | null;
    divisionId: string;
    mealId: string;

    constructor(date: string | null, divisionId: string, mealId: string) {
        this.date = date;
        this.divisionId = divisionId;
        this.mealId = mealId;
    }
}
