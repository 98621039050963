import axios, {AxiosError, AxiosInstance} from "axios";
import DateUtils from "../utils/DateUtils";

export default abstract class AbstractService {
    // private static getApi = () => {
    //     const {
    //         REACT_APP_HOST_BACKEND_DEV,
    //         REACT_APP_HOST_BACKEND_PROD,
    //         REACT_APP_STAGE,
    //     } = process.env
    //     let HOST_BACKEND = REACT_APP_STAGE === "DEV" ? REACT_APP_HOST_BACKEND_DEV : REACT_APP_HOST_BACKEND_PROD
    //
    //     const accessToken = localStorage.getItem('accessToken')
    //     const headers: any = accessToken ? {Authorization: `Bearer ${accessToken}`} : {}
    //
    //     const api = axios.create(
    //         {
    //             headers: {...headers, accept: "*/*"},
    //             baseURL: `${HOST_BACKEND}`
    //         });
    //
    //     api.interceptors.request.use(config => {
    //         console.log('fulfilled', config)
    //         // const headers = authHeaders()
    //         // config.headers = {...config.headers, ...headers}
    //         return config
    //     }, (config: AxiosError) => {
    //         console.log(config)
    //         return config
    //     })
    //
    //     api.interceptors.response.use(r => {
    //         console.log(r)
    //         DateUtils.handleDates(r);
    //         return r;
    //     }, (config: AxiosError) => {
    //         const {status} = config.request
    //
    //         // if (status === 401)
    //         //     window.history.pushState(null, 'Авторизация','/auth')
    //
    //         return config
    //     })
    //
    //     return api;
    // }
    //
    // private static getApiSecure = () => {
    //     const { REACT_APP_HOST_BACKEND_DEV, REACT_APP_HOST_BACKEND_PROD, REACT_APP_STAGE} = process.env
    //     let HOST_BACKEND = REACT_APP_STAGE === "DEV" ? REACT_APP_HOST_BACKEND_DEV : REACT_APP_HOST_BACKEND_PROD
    //
    //     const api = axios.create(
    //         {
    //             baseURL: `${HOST_BACKEND}`,
    //         });
    //
    //     api.interceptors.response.use(r => {
    //         DateUtils.handleDates(r);
    //         return r;
    //     })
    //
    //     return api;
    // }
    //
    // protected static api: AxiosInstance = this.getApi();
    // protected static apiSecure: AxiosInstance = this.getApiSecure();
}

