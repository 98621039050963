import React, {FC, Ref, useEffect} from "react";

interface UseClickOutsideProps {
    ref: any
    stopEvent?: boolean
    conditionClick?: () => boolean
    handleOutside?: () => void
}

const useClickOutside = ({ref, handleOutside, stopEvent, conditionClick = () => true}: UseClickOutsideProps) => {

    useEffect(() => {
        if (!stopEvent) {
            const handleClickOutside = (e: any) => {
                if (ref.current && !ref.current.contains(e.target) && conditionClick && conditionClick() && handleOutside) {
                    handleOutside()
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document?.removeEventListener("mousedown", handleClickOutside);
            }
        }
    }, [ref, stopEvent])
}

export default useClickOutside
