import ReferenceDto from "../../common/dto/ReferenceDto";

export enum TYPE_NOTIFICATION {
    ORDER_UPDATED = 'ORDER_UPDATED',
    REQUEST_FEEDBACK = 'REQUEST_FEEDBACK',
    REQUEST_CONTACT_INFO = 'REQUEST_CONTACT_INFO'
}

export enum STATUS_NOTIFICATION {
    NEW = 'NEW', UNREAD = 'UNREAD', READ = 'READ', ARCHIVE = 'ARCHIVE'
}

export interface INotificationDto extends ReferenceDto{
    title: string
    data: string
    message: string
    status: STATUS_NOTIFICATION
    personnelNumber: string
    type: TYPE_NOTIFICATION
    timestamp: string
}
