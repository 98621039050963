import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReAuth} from "./index";
import {PagePaginationApiResponse, PagePaginationApiResponseExtend} from "../common/dto/PageApiResponse";
import {INotificationDto} from "../entity/notification";
import {PaginationNotification} from "../common/dto/Page";
import {setCountUnread} from "../redux/reducers/notificationsSlice";

export const notificationApi = createApi({
    reducerPath: 'notifications',
    tagTypes: ['Notifications'],
    refetchOnReconnect: true,
    baseQuery: baseQueryWithReAuth,
    endpoints: (build) => ({
        getAllNotifications: build.query<PagePaginationApiResponseExtend<INotificationDto, PaginationNotification>, { personnelNumber: string, page?: number }>({
            query: ({personnelNumber, page = 0}) => {
                const params = {
                    personnelNumber,
                    page
                }

                return ({
                    url: 'notifications/',
                    method: 'GET',
                    params
                })
            }, async onQueryStarted(args, {dispatch, queryFulfilled, requestId}): Promise<void> {
                try {
                    const {data} = await queryFulfilled

                    if (data && data?.body?.unreadCount) {
                        dispatch(setCountUnread(data.body?.unreadCount ?? 0))
                    }
                } catch (e) {

                }
            }, providesTags: ['Notifications']
        }),
        readAllNotifications: build.mutation<PagePaginationApiResponseExtend<INotificationDto, PaginationNotification>, { personnelNumber: string }>({
            query: ({personnelNumber}) => {
                const params = {
                    personnelNumber
                }

                return ({
                    url: 'notifications/',
                    method: 'PUT',
                    params
                })
            }, async onQueryStarted(args, {dispatch, queryFulfilled, requestId}): Promise<void> {
                try {
                    const {data} = await queryFulfilled

                    if (data?.body) {
                        dispatch(setCountUnread(data.body?.unreadCount ?? 0))
                    }
                } catch (e) {

                }
            }, invalidatesTags: ['Notifications']
        }),
        readByIdNotifications: build.mutation<PagePaginationApiResponseExtend<INotificationDto, PaginationNotification>, { personnelNumber: string, id: string }>({
            query: ({personnelNumber, id}) => {
                const params = {
                    id,
                    personnelNumber
                }

                return ({
                    url: `notifications/${id}`,
                    method: 'PUT',
                    params
                })
            }, async onQueryStarted(args, {dispatch, queryFulfilled, requestId}): Promise<void> {
                try {
                    const {data} = await queryFulfilled

                    if (data?.body) {
                        dispatch(setCountUnread(data.body?.unreadCount ?? 0))
                    }
                } catch (e) {

                }
            }, invalidatesTags: ['Notifications']
        }),
        deleteAllNotifications: build.mutation<PagePaginationApiResponseExtend<INotificationDto, PaginationNotification>, { personnelNumber: string }>({
            query: ({personnelNumber}) => {
                const params = {
                    personnelNumber
                }

                return ({
                    url: 'notifications/',
                    method: 'DELETE',
                    params
                })
            }, invalidatesTags: ['Notifications']
        }),
        deleteByIdNotifications: build.mutation<PagePaginationApiResponseExtend<INotificationDto, PaginationNotification>, { personnelNumber: string, id: string }>({
            query: ({personnelNumber, id}) => {
                const params = {
                    id,
                    personnelNumber
                }

                return ({
                    url: `notifications/${id}`,
                    method: 'DELETE',
                    params
                })
            }, invalidatesTags: ['Notifications']
        }),
    })
})
