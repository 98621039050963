import React, {memo, useCallback, useState} from "react";
import OffCanvas from "react-bootstrap/Offcanvas";
import SvgButton from "../UI/button/SVGButton";
import {NavLink} from "react-router-dom";
import {RFCWithAnyProps, Page} from "../../common/types";
import MenuItem from "../menu/MenuItem";
import ListGroup from "react-bootstrap/ListGroup";
import {useAppSelector} from "../../redux/hooks";
import {clearTokensLocalStorage} from "../../api/axiosInstances";
import menuSvg from '../../assets/icons/menu.svg';
import closeMenuSvg from '../../assets/icons/closeMenu.svg';
import {
    countActiveOrders,
    selectCountUnreadNotifications
} from "../../redux/selectors/selectors";

interface IProps {
    pages: Page[];
}

const RightMenu: RFCWithAnyProps<IProps> = ({pages, ...props}) => {
    const countOrders = useAppSelector(countActiveOrders)
    const countNotifications = useAppSelector(state => selectCountUnreadNotifications(state))
    const [show, setShow] = useState(false);
    const logOutPageProps = pages.find(p => p.path === 'logout')

    const handleClose = useCallback(() => setShow(false), [])

    const handleShow = useCallback(() => setShow(true), [])

    return (
        <>
            <SvgButton
                onClick={handleShow}
                svg={menuSvg}
                className="btn btn-white menu_btn">
            </SvgButton>
            <OffCanvas
                show={show}
                onHide={handleClose}
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                placement="end"
                aria-labelledby="offcanvasMenuLabel">
                <OffCanvas.Header>
                    <OffCanvas.Title as={"h3"}>Меню</OffCanvas.Title>
                    <SvgButton
                        svg={closeMenuSvg}
                        onClick={handleClose}
                        aria-label="Close"
                        className="btn p-0 btn-white menu_btn btnMenuClose"/>
                </OffCanvas.Header>
                <OffCanvas.Body>
                    <ListGroup as="ul" className="mainMenuList">
                        {
                            pages.filter(i => !(i.notInMenu ?? false)).map(p => {
                                if (p.path.includes("orders"))
                                    return <NavLink key={p.path} to={p.path}
                                                    className={({isActive}) => (isActive ? 'active' : 'inactive')}
                                                    onClick={handleClose}>
                                        <MenuItem data={{name: p.name, path: p.path, icon: p.icon}}
                                                  countNotifications={countOrders}/>
                                    </NavLink>

                                return <NavLink key={p.path} to={p.path}
                                                className={({isActive}) => (isActive ? 'active' : 'inactive')}
                                                onClick={handleClose}>
                                    <MenuItem data={{name: p.name, path: p.path, icon: p.icon}}/>
                                </NavLink>
                            })
                        }
                    </ListGroup>
                </OffCanvas.Body>
                <div className="offcanvas-footer">
                    {
                        logOutPageProps && <div className="logOutBtn">
                            <MenuItem className="btn btn-outline-primary" data={{
                                name: logOutPageProps?.name,
                                path: logOutPageProps?.path,
                                icon: logOutPageProps?.icon
                            }} onCLick={async () => {
                                await clearTokensLocalStorage()
                                window.location.replace('/auth')
                            }}/>
                        </div>
                    }
                </div>
            </OffCanvas>
        </>
    )
}

export default memo(RightMenu);
