import EntityApiResponse from "../../common/dto/EntityApiResponse";
import ClientProfile from "./ClientProfile";
import AbstractService from "../../common/AbstractService";
import { api } from "../../api/axiosInstances";

export default class ClientProfileService extends AbstractService{

    static async getProfile(personnelNumber: string): Promise<EntityApiResponse<ClientProfile>> {
        const response = await api.get<EntityApiResponse<ClientProfile>>
        ('/profile/' + personnelNumber, {});
        return response.data;
    }
}
