import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";
import OrderDto, {OrderStatus, OrderStatusDto} from "../../entity/order/OrderDto";
import {DivisionSortType} from "../../components/dropDown/division/DivisionDropdown";
import {notificationApi} from "../../api/notificationApi";
import {STATUS_NOTIFICATION} from "../../entity/notification";
import {orderApi} from "../../api/orderApi";

type Selector<S> = (state: RootState) => S;

export const cartItemSelector = createSelector((state: RootState) => state.cartReducer.cart,
    (state: RootState, selectNomenclatureRef?: string) => selectNomenclatureRef, (cart, ref) => {
        return ref ? cart?.items?.find(i => i.nomenclature.ref === ref) : undefined
    })

export const countInCartPositionSelector = (state: RootState) => state.cartReducer?.cart?.items?.length ?? 0

export const countActiveOrders = createSelector((sate: RootState) => sate.orderReducer.orders, (orders) => {
    return orders.reduce((prev: number, cur: OrderDto) => cur.status !==
    (OrderStatus.COMPLETED || OrderStatus.CANCELED_BY_OPERATOR || OrderStatus.CANCELED_BY_CLIENT) ? ++prev : prev, 0)
})

export const divisionSorted = createSelector((state: RootState) => state.divisionReducer.divisions,
    (state: RootState, sortType?: DivisionSortType) => sortType, (divisions, sort) => {
        return sort ? divisions.filter(d => sort === DivisionSortType.ACTIVE && d.active) : divisions
    })

export const allowedUntilCart = (state: RootState) => state.cartReducer.cart?.allowedUntil

export const technicalSupportContactInfoSelect = (state: RootState) => state.profileReducer.clientData ?
    state.profileReducer.clientData.technicalSupportContactInfo ?
        state.profileReducer.clientData.technicalSupportContactInfo : [] : []

export const qualityAndServiceContactInfoSelect = (state: RootState) => state.profileReducer.clientData ?
    state.profileReducer.clientData.qualityAndServiceContactInfo ?
        state.profileReducer.clientData.qualityAndServiceContactInfo : [] : []

export const cartsSelect = (state: RootState) => {
    if (state.cartReducer.cart) {
        const cart = state.cartReducer.carts.find(c => c.ref === state.cartReducer.cart?.ref)

        if (cart)
            return [cart, ...state.cartReducer.carts.filter(c => c.ref !== cart.ref)];
        else
            return state.cartReducer.carts
    }

    return state.cartReducer.carts
}

const allNotificationsSelector = notificationApi.endpoints.getAllNotifications.select

const allNotificationsResult = (state: RootState) => {
    const personnelNumber = selectCurrentPersonalNumber(state);
    return allNotificationsSelector({personnelNumber})
}

const selectCurrentPersonalNumber = createSelector((state: RootState) => state, (state) => state.profileReducer.personnelNumber)

export const selectCountNotifications = createSelector([selectCurrentPersonalNumber, allNotificationsResult,
    (state: RootState) => state], (cpn, selector, state) =>
    selector(state)?.data?.body.unreadCount ?? 0)

export const selectCountUnreadNotifications = (state: RootState) => state.notificationReducer.countUnreadNotifications

const selectResultStatuses = orderApi.endpoints?.getOrderStatuses.select(null)
const emptyStatuses: OrderStatusDto[] = []
export const selectAllUStatuses = createSelector(
    selectResultStatuses,
    statusesResult => statusesResult?.data?.body ?? emptyStatuses
)