import React, {CSSProperties, FC} from 'react';
import './style.scss'

type PositionBadge = "relative" | "absolute"

interface BadgeProps {
    count?: number
    position?: PositionBadge
    style?: CSSProperties | undefined;
}

const Badge: FC<BadgeProps> = ({count = 0, position = "absolute", style}) => {

    if (!count || count === 0)
        return null

    return (
        <span
            className={`badge rounded-pill bg-danger ${position === "absolute" ? "bAbsolute" : "bRelative"}`}
            style={style}>{count}</span>

    );
}

export default Badge;
